import ToolkitVideos from 'components/formative-assessment/fstks/ToolkitVideos'
import WhatYouGet from 'components/formative-assessment/fstks/WhatYouGet'
import Resource from 'components/global/Resource'
import SectionTitle from 'components/global/SectionTitle'
import { Col, Row, Section } from 'components/grid'
import Layout from 'components/Layout'
import React from 'react'

export default function ToolkitsPage() {
	return (
		<Layout pageName="Formative Assessment">
			<Section flatGray>
				<Row className="items-center py-20">
					<Col width="w-full lg:w-7/12" className="pl-4 lg:pl-12">
						<h1>
							IRLA
							<sup>®</sup> & Foundational Skills Toolkits
						</h1>
						<p>
							Help teachers make the shift to delivering Foundational Skills instruction in flexible, strategic small-groups. The IRLA Toolkits and Foundational
							Skills Toolkits enable teachers to provide differentiated, targeted, and efficient skills instruction embedded into one-on-one conferences and strategy
							plans.
						</p>
					</Col>
					<Col width="w-full lg:w-5/12" className="hidden lg:block">
						<Resource title="FSTK | 1B" noLink />
					</Col>
				</Row>
			</Section>
			<Section margin="mt-40">
				<SectionTitle title="IRLA Toolkit Videos" />
				<ToolkitVideos />
			</Section>
			<Section margin="mt-40">
				<SectionTitle title="What You Get" />
				<WhatYouGet />
			</Section>
		</Layout>
	)
}
