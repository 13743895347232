import Module1B from 'components/formative-assessment/fstks/WhatYouGet/Levels/Modules/1B'
import { Col, Row } from 'components/grid'
import React from 'react'

const Level1B = () => {
	return (
		<Row className="mt-6">
			<Col width="w-full">
				<Module1B />
			</Col>
		</Row>
	)
}

export default Level1B
