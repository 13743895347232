import ModuleY from 'components/formative-assessment/fstks/WhatYouGet/Levels/Modules/Y'
import React from 'react'

const LevelY = () => {
	return (
		<div className="mt-6">
			<ModuleY />
		</div>
	)
}

export default LevelY
