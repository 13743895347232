import { Col, Row } from 'components/grid'
import React from 'react'

import LevelSelector from './LevelSelector'

const WhatYouGet = () => {
	return (
		<Row>
			<Col>
				<LevelSelector />
			</Col>
		</Row>
	)
}

export default WhatYouGet
