import AdditionalChapterBooks from 'components/formative-assessment/fstks/WhatYouGet/Levels/Modules/ModuleItems/AdditionalChapterBooks'
import Handbook from 'components/formative-assessment/fstks/WhatYouGet/Levels/Modules/ModuleItems/Handbook'
import SmallGroupTexts from 'components/formative-assessment/fstks/WhatYouGet/Levels/Modules/ModuleItems/SmallGroupTexts'
import { Col, Row } from 'components/grid'
import React from 'react'

const Module2R = () => {
	return (
		<>
			<h4 className="mb-6 text-center">The 2R module includes the following materials</h4>
			<Row center>
				<Col width="w-1/2 lg:w-1/4" className="material-column">
					<Handbook name="2R" />
				</Col>
				<Col width="w-1/2 lg:w-1/4" className="material-column">
					<SmallGroupTexts name="2R" />
				</Col>
				<Col width="w-1/2 lg:w-1/4" className="material-column">
					<AdditionalChapterBooks />
				</Col>
			</Row>
		</>
	)
}

export default Module2R
