import Module2B from 'components/formative-assessment/fstks/WhatYouGet/Levels/Modules/2B'
import React from 'react'

const Level1B = () => {
	return (
		<div className="mt-6">
			<Module2B />
		</div>
	)
}

export default Level1B
