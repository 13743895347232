import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import LevelY2R from 'components/formative-assessment/fstks/WhatYouGet/Levels/Y-2R'
import LevelY from 'components/formative-assessment/fstks/WhatYouGet/Levels/Y'
import Level1G from 'components/formative-assessment/fstks/WhatYouGet/Levels/1G'
import Level2G from 'components/formative-assessment/fstks/WhatYouGet/Levels/2G'
import Level1B from 'components/formative-assessment/fstks/WhatYouGet/Levels/1B'
import Level2B from 'components/formative-assessment/fstks/WhatYouGet/Levels/2B'
import Level1R from 'components/formative-assessment/fstks/WhatYouGet/Levels/1R'
import Level2R from 'components/formative-assessment/fstks/WhatYouGet/Levels/2R'

const LevelSelector = () => {
	const [index, setIndex] = useState(0)

	return (
		<Tabs tabIndex={index} onSelect={(index) => setIndex(index)} selectedTabClassName={` ${determineTabColor(index)} font-medium text-white`}>
			<TabList className="flex overflow-x-auto">
				<Tab className="min-w-max flex-grow cursor-pointer rounded-t border-t border-l border-r hover:bg-core-b hover:text-white">
					<span className="block px-4 py-2 text-center">Full Kit Y-2R</span>
					<div className="y-r-gradient h-1" />
				</Tab>
				<Tab className="min-w-max flex-grow cursor-pointer rounded-t border-t border-l border-r hover:bg-core-y hover:text-white">
					<span className="block px-4 py-2 text-center">Y</span>
					<div className="h-1 bg-core-y" />
				</Tab>
				<Tab className="min-w-max flex-grow cursor-pointer rounded-t border-t border-l border-r hover:bg-core-g hover:text-white">
					<span className="block px-4 py-2 text-center">1G</span>
					<div className="h-1 bg-core-g" />
				</Tab>
				<Tab className="min-w-max flex-grow cursor-pointer rounded-t border-t border-l border-r hover:bg-core-g hover:text-white">
					<span className="block px-4 py-2 text-center">2G</span>
					<div className="h-1 bg-core-g" />
				</Tab>
				<Tab className="min-w-max flex-grow cursor-pointer rounded-t border-t border-l border-r hover:bg-core-b hover:text-white">
					<span className="block px-4 py-2 text-center">1B</span>
					<div className="h-1 bg-core-b" />
				</Tab>
				<Tab className="min-w-max flex-grow cursor-pointer rounded-t border-t border-l border-r hover:bg-core-b hover:text-white">
					<span className="block px-4 py-2 text-center">2B</span>
					<div className="h-1 bg-core-b" />
				</Tab>
				<Tab className="min-w-max flex-grow cursor-pointer rounded-t border-t border-l border-r hover:bg-core-r hover:text-white">
					<span className="block px-4 py-2 text-center">1R</span>
					<div className="h-1 bg-core-r" />
				</Tab>
				<Tab className="min-w-max flex-grow cursor-pointer rounded-t border-t border-l border-r hover:bg-core-r hover:text-white">
					<span className="block px-4 py-2 text-center">2R</span>
					<div className="h-1 bg-core-r" />
				</Tab>
				{/* <Tab className="ml-6 min-w-max flex-grow cursor-pointer rounded-t border-t border-l border-r hover:bg-slate-50">
					<span className="block px-4 py-2 text-center">Full Kit Wt-Pu</span>
					<div className="wt-pu-gradient h-1" />
				</Tab>
				<Tab className="min-w-max flex-grow cursor-pointer rounded-t border-t border-l border-r hover:bg-slate-50">
					<span className="block px-4 py-2 text-center">Wt</span>
					<div className="h-1 bg-core-wt" />
				</Tab>
				<Tab className="min-w-max flex-grow cursor-pointer rounded-t border-t border-l border-r hover:bg-slate-50">
					<span className="block px-4 py-2 text-center">Bk</span>
					<div className="h-1 bg-core-bk" />
				</Tab>
				<Tab className="min-w-max flex-grow cursor-pointer rounded-t border-t border-l border-r hover:bg-slate-50">
					<span className="block px-4 py-2 text-center">Or</span>
					<div className="h-1 bg-core-or" />
				</Tab>
				<Tab className="min-w-max flex-grow cursor-pointer rounded-t border-t border-l border-r hover:bg-slate-50">
					<span className="block px-4 py-2 text-center">Pu</span>
					<div className="h-1 bg-core-pu" />
				</Tab> */}
			</TabList>
			<TabPanel>
				<LevelY2R />
			</TabPanel>
			<TabPanel>
				<LevelY />
			</TabPanel>
			<TabPanel>
				<Level1G />
			</TabPanel>
			<TabPanel>
				<Level2G />
			</TabPanel>
			<TabPanel>
				<Level1B />
			</TabPanel>
			<TabPanel>
				<Level2B />
			</TabPanel>
			<TabPanel>
				<Level1R />
			</TabPanel>
			<TabPanel>
				<Level2R />
			</TabPanel>
			{/* <TabPanel>Wt-Pu</TabPanel>
			<TabPanel>Wt</TabPanel>
			<TabPanel>Bk</TabPanel>
			<TabPanel>Or</TabPanel>
			<TabPanel>Pu</TabPanel> */}
		</Tabs>
	)
}

export default LevelSelector

function determineTabColor(index: number) {
	if (index === 1) return 'bg-core-y'
	if (index === 2 || index === 3) return 'bg-core-g'
	if (index === 0 || index === 4 || index === 5) return 'bg-core-b'
	if (index === 6 || index === 7) return 'bg-core-r'
}
