import Module2R from 'components/formative-assessment/fstks/WhatYouGet/Levels/Modules/2R'
import React from 'react'

const Level2R = () => {
	return (
		<div className="mt-6">
			<Module2R />
		</div>
	)
}

export default Level2R
