import React, { useState } from 'react'
import { Collapse } from 'react-collapse'

import Module1B from './Modules/1B'
import Module1G from './Modules/1G'
import Module1R from './Modules/1R'
import Module2B from './Modules/2B'
import Module2G from './Modules/2G'
import Module2R from './Modules/2R'
import ModuleY from './Modules/Y'

const LevelY2R = () => {
	const [level, setLevel] = useState('')
	const [isOpen, setIsOpen] = useState(false)

	function determineModule(level: string) {
		if (level === 'y') return <ModuleY />
		if (level === '1g') return <Module1G />
		if (level === '2g') return <Module2G />
		if (level === '1b') return <Module1B />
		if (level === '2b') return <Module2B />
		if (level === '1r') return <Module1R />
		if (level === '2r') return <Module2R />
		else return
	}

	function handleLevelChange(lvl: string) {
		if (lvl === level) return
		if (!level) {
			setLevel(lvl)
			setIsOpen(true)
		} else {
			setLevel(lvl)
			setIsOpen(false)
			setTimeout(() => {
				setIsOpen(true)
			}, 300)
		}
	}

	return (
		<div className="mt-6">
			<h4 className="mb-6 text-center">The Y-2R collection includes the following modules and materials</h4>
			<div className="mb-4 flex flex-grow gap-3">
				{levels.map((lvl) => {
					return (
						<div className="w-1/7">
							<button className="hover-zoom" onClick={() => handleLevelChange(lvl.level)}>
								<img className="mb-2" src={lvl.image} alt={`${lvl.level} toolkit`} />
								<span className={`inline-block text-center text-xl font-semibold uppercase ${lvl.level === level ? 'text-ab-100' : ''}`}>{lvl.level}</span>
							</button>
						</div>
					)
				})}
			</div>
			<Collapse
				isOpened={isOpen}
				theme={{
					collapse: 'ReactCollapse--collapse transition-all duration-200 ease-in-out',
					content: 'ReactCollapse--content'
				}}
			>
				{determineModule(level)}
			</Collapse>
		</div>
	)
}

export default LevelY2R

const levels = [
	{
		level: 'y',
		image: 'https://images.ctfassets.net/xo7k5gkhtsb4/1LxD5P5jlWXXWoRdwlVLfZ/256d375438d079eff1db745e924aed9d/IRLA_Foundational_Skills_Toolkits_Y.png'
	},
	{
		level: '1g',
		image: 'https://images.ctfassets.net/xo7k5gkhtsb4/46Ou9kExPkO13h9LnSgIHA/513f7a82f61133ad4165cb88e692d07a/IRLA_Foundational_Skills_Toolkits_1G.png'
	},
	{
		level: '2g',
		image: 'https://images.ctfassets.net/xo7k5gkhtsb4/5h6HCz7rGwzX4evyEAFtvc/7cd629969fb2b3763ce6a3a94e9e9a58/IRLA_Foundational_Skills_Toolkits_2G.png'
	},
	{ level: '1b', image: 'https://images.ctfassets.net/xo7k5gkhtsb4/1YTWIdsrMIEEGhHpLgbFGy/716d0406f1f72ccf782afd3d3559c07b/IRLA_Foundational_Skills_Toolkits_1B.png' },
	{ level: '2b', image: 'https://images.ctfassets.net/xo7k5gkhtsb4/6ckAuO8x6Dm5b2m0cDApzo/37c7637dd688e9651fc443862337fa41/IRLA_Foundational_Skills_Toolkits_2B.png' },
	{ level: '1r', image: 'https://images.ctfassets.net/xo7k5gkhtsb4/1OmCPubUXu8mDpYXCfG7fk/85e3bd3fdcb83ecec01e4d4b4db45283/IRLA_Foundational_Skills_Toolkits_1R.png' },
	{ level: '2r', image: 'https://images.ctfassets.net/xo7k5gkhtsb4/1qFdE7kRjNWR8UW4j4UPZW/c77791913355f94842b499e7bd4656d1/IRLA_Foundational_Skills_Toolkits_2R.png' }
]
