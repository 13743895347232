import Handbook from 'components/formative-assessment/fstks/WhatYouGet/Levels/Modules/ModuleItems/Handbook'
import SeriesAuthorStudy from 'components/formative-assessment/fstks/WhatYouGet/Levels/Modules/ModuleItems/SeriesAuthorStudy'
import SmallGroupTexts from 'components/formative-assessment/fstks/WhatYouGet/Levels/Modules/ModuleItems/SmallGroupTexts'
import { Col, Row } from 'components/grid'
import React from 'react'

const Module1R = () => {
	return (
		<>
			<h4 className="mb-6 text-center">The 1R module includes the following materials</h4>
			<Row center>
				<Col width="w-1/2 lg:w-1/4" className="material-column">
					<Handbook name="1R" />
				</Col>
				<Col width="w-1/2 lg:w-1/4" className="material-column">
					<SmallGroupTexts name="1R" />
				</Col>
				<Col width="w-1/2 lg:w-1/4" className="material-column">
					<SeriesAuthorStudy />
				</Col>
			</Row>
		</>
	)
}

export default Module1R
