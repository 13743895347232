import Handbook from 'components/formative-assessment/fstks/WhatYouGet/Levels/Modules/ModuleItems/Handbook'
import ICPBooks from 'components/formative-assessment/fstks/WhatYouGet/Levels/Modules/ModuleItems/ICPBooks'
import ICPCards from 'components/formative-assessment/fstks/WhatYouGet/Levels/Modules/ModuleItems/ICPCards'
import { Col, Row } from 'components/grid'
import React from 'react'

import SmallGroupTexts from 'components/formative-assessment/fstks/WhatYouGet/Levels/Modules/ModuleItems/SmallGroupTexts'

const ModuleY = () => {
	return (
		<>
			<h4 className="mb-6 text-center">The Y module includes the following materials</h4>
			<Row center>
				<Col width="w-1/2 lg:w-1/4" className="material-column">
					<Handbook name="Y" />
				</Col>
				<Col width="w-1/2 lg:w-1/4" className="material-column">
					<ICPCards />
				</Col>
				<Col width="w-1/2 lg:w-1/4" className="material-column">
					<ICPBooks />
				</Col>
				<Col width="w-1/2 lg:w-1/4" className="material-column">
					<SmallGroupTexts name="Y" />
				</Col>
			</Row>
		</>
	)
}

export default ModuleY
