import Module1G from 'components/formative-assessment/fstks/WhatYouGet/Levels/Modules/1G'
import React from 'react'

const Level1G = () => {
	return (
		<div className="mt-6">
			<Module1G />
		</div>
	)
}

export default Level1G
