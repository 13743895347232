import { ModalQuery } from 'common-types'
import MaterialButton from 'components/arc-core/MaterialButton'
import Modal from 'components/global/Modal'
import { Col, Row } from 'components/grid'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

const WordsGameSet = () => {
	const [show, setShow] = React.useState(false)
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const data = useStaticQuery<ModalQuery>(graphql`
		query {
			contentfulModal(name: { eq: "Category Words Game Set" }) {
				title
				description {
					description
				}
				image {
					gatsbyImageData(placeholder: BLURRED)
				}
			}
		}
	`)

	const title = data.contentfulModal.title
	const description = data.contentfulModal.description.description
	const image = data.contentfulModal.image[0].gatsbyImageData

	return (
		<React.Fragment>
			<MaterialButton onClick={handleShow} image={image} title={title} />
			<Modal title={title} isOpen={show} handleClose={handleClose}>
				<Row>
					<Col>
						<p className="text-lg text-slate-400">{description}</p>
					</Col>
					<Col>
						<GatsbyImage image={image} alt={title} />
					</Col>
				</Row>
			</Modal>
		</React.Fragment>
	)
}

export default WordsGameSet
