import Handbook from 'components/formative-assessment/fstks/WhatYouGet/Levels/Modules/ModuleItems/Handbook'
import PowerWordsKit from 'components/formative-assessment/fstks/WhatYouGet/Levels/Modules/ModuleItems/PowerWordsKit'
import SmallGroupTexts from 'components/formative-assessment/fstks/WhatYouGet/Levels/Modules/ModuleItems/SmallGroupTexts'
import { Col, Row } from 'components/grid'
import React from 'react'

import InitalBlendsPictureCards from 'components/formative-assessment/fstks/WhatYouGet/Levels/Modules/ModuleItems/InitalBlendsPictureCards'
import WordsGameSet from './ModuleItems/WordsGameSet'

const Module2G = () => {
	return (
		<>
			<h4 className="mb-6 text-center">The 2G module includes the following materials</h4>
			<Row center>
				<Col width="w-1/2 lg:w-1/5" className="material-column">
					<Handbook name="2G" />
				</Col>
				<Col width="w-1/2 lg:w-1/5" className="material-column">
					<PowerWordsKit name="2G" />
				</Col>
				<Col width="w-1/2 lg:w-1/5" className="material-column">
					<SmallGroupTexts name="2G" />
				</Col>
				<Col width="w-1/2 lg:w-1/5" className="material-column">
					<WordsGameSet />
				</Col>
				<Col width="w-1/2 lg:w-1/5" className="material-column">
					<InitalBlendsPictureCards />
				</Col>
			</Row>
		</>
	)
}

export default Module2G
