import Module1R from 'components/formative-assessment/fstks/WhatYouGet/Levels/Modules/1R'
import React from 'react'

const Level1R = () => {
	return (
		<div className="mt-6">
			<Module1R />
		</div>
	)
}

export default Level1R
