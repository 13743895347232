import Handbook from 'components/formative-assessment/fstks/WhatYouGet/Levels/Modules/ModuleItems/Handbook'
import PowerWordsKit from 'components/formative-assessment/fstks/WhatYouGet/Levels/Modules/ModuleItems/PowerWordsKit'
import SmallGroupTexts from 'components/formative-assessment/fstks/WhatYouGet/Levels/Modules/ModuleItems/SmallGroupTexts'
import { Col, Row } from 'components/grid'
import React from 'react'

const Module1G = () => {
	return (
		<>
			<h4 className="mb-6 text-center">The 1G module includes the following materials</h4>
			<Row center>
				<Col width="w-1/2 lg:w-1/4" className="material-column">
					<Handbook name="1G" />
				</Col>
				<Col width="w-1/2 lg:w-1/4" className="material-column">
					<PowerWordsKit name="1G" />
				</Col>
				<Col width="w-1/2 lg:w-1/4" className="material-column">
					<SmallGroupTexts name="1G" />
				</Col>
			</Row>
		</>
	)
}

export default Module1G
