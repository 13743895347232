import Module2G from 'components/formative-assessment/fstks/WhatYouGet/Levels/Modules/2G'
import React from 'react'

const Level2G = () => {
	return (
		<div className="mt-6">
			<Module2G />
		</div>
	)
}

export default Level2G
