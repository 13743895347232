import { Col, Row } from 'components/grid'
import OverlayVideo from 'components/overlay-video'
import React from 'react'

const ToolkitVideos = () => {
	return (
		<Row>
			<Col width="w-full md:w-1/2">
				<OverlayVideo title="Small Group Lesson: 1B Lesson 1: Onset & Rime" />
				<p className="mt-1 text-center font-semibold">Grade 1 Demo</p>
			</Col>
			<Col width="w-full md:w-1/2">
				<OverlayVideo title="FSTK Overview" />
				<p className="mt-1 text-center font-semibold">Foundational Skills Toolkits Overview</p>
			</Col>
		</Row>
	)
}

export default ToolkitVideos
